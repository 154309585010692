.input-root {
  width: 100%;
  height: 100%;
  padding: 11px 16px;
  border: 1px solid #eaeaea;
  color: #1d3740;
}
.input-root::-moz-placeholder {
  color: #73858f;
}
.input-root::placeholder {
  color: #73858f;
}
.input-root.filled {
  border-color: #5fa87c;
}/*# sourceMappingURL=input.style.css.map */