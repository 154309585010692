.toggle-root {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f1f2f0;
  border-radius: 24px;
  padding: 3px;
}
.toggle-root .ball-layer {
  position: absolute;
  z-index: 1;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  display: flex;
  gap: 10px;
}
.toggle-root .ball-layer div {
  flex: 1;
}
.toggle-root .ball-layer .ball {
  transition: transform 0.2s cubic-bezier(0.33, -0.44, 0.67, 1.36);
  transform: translateX();
  background-color: #fff;
  border-radius: 21px;
}
.toggle-root .text-layer {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  position: absolute;
  z-index: 2;
  display: flex;
  gap: 10px;
}
.toggle-root .text-layer .text-container {
  transition: color 0.2s linear;
  flex: 1;
  font-size: 15px;
  color: #73858f;
}
.toggle-root .text-layer .active {
  color: #1d3740;
}/*# sourceMappingURL=toggle.style.css.map */