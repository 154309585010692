@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import './colors';

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000;
  letter-spacing: -0.6px;
  touch-action: pan-y;
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
}

input {
  outline: none;
  background-color: transparent;
}

button {
  cursor: pointer;
  outline: none;
  background-color: transparent;
}

html {
  font-size: 16px;
}

#root {
  background-color: $gray-1;
}
