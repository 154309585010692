.tooltip-root {
  padding: 15px 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #eaeaea;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.16);
}
.tooltip-root .x-value {
  color: #73858f;
  font-size: 12px;
  margin-bottom: 8px;
}
.tooltip-root .y-value {
  color: #1d3740;
  font-size: 16px;
  font-weight: bold;
}/*# sourceMappingURL=tooltip.style.css.map */