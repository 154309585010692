@import '../../../styles/colors';

.login-root {
  width: 100%;
  height: 100vh;
  background-color: $gray-1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title-container {
    .title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      color: $black;
    }
    .subtitle {
      font-size: 16px;
      text-align: center;
      color: $gray-3;
      margin-top: 12px;
    }
  }

  .login-form {
    margin-top: 40px;
    width: 444px;
    border: 1px solid $gray-2;
    background-color: $white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;

    .header {
      width: 100%;
      border-bottom: 1px solid $gray-2;
      padding: 18px 32px 16px 32px;
      margin-bottom: 24x;
    }
    .item {
      width: 100%;
      padding: 0px 32px;
      margin-top: 24px;
      .label {
        font-size: 14px;
        font-weight: 500;
        color: $gray-4;
      }
      .input-container {
        margin-top: 8px;
      }
    }

    .login-button {
      width: calc(100% - 64px);
      margin-top: 40px;
      height: 40px;
      background-color: $primary;
      color: $white;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
