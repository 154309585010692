.video-item {
  width: 100%;
  height: 436px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
}
.video-item .header {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  gap: 12px;
  background-color: #fff;
}
.video-item .header .index-box {
  width: 36px;
  height: 36px;
  background-color: #5fa87c;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 2px;
}
.video-item .header .device-name {
  font-size: 18px;
  font-weight: 500;
  color: #111111;
}
.video-item .content {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: rgb(187, 183, 183);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}
.video-item .video-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 0;
}
.video-item .control-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.video-item .control-layer .size-btn {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;
}
.video-item .control-layer .power-state {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 120px;
  height: 40px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}
.video-item .control-layer .state {
  width: 8px;
  height: 8px;
  border: 1px solid #fff;
  border-radius: 4px;
  margin-right: 6px;
  background: radial-gradient(circle at 50% 50%, #ff8171, #ff2b2b 71%);
}
.video-item .control-layer .text {
  color: #fff;
  font-size: 18px;
}
.video-item .control-layer .off .state {
  background: radial-gradient(circle at 50% 50%, #ccdde6, #73858f 71%);
}
.video-item .control-layer .off .text {
  color: #73858f;
}

.expanded {
  height: auto;
  min-height: 800px;
  grid-column: span 2;
  grid-row: span 2;
}/*# sourceMappingURL=video.item.css.map */