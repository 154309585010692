@import '../../../../styles/colors';

.textchip-root {
  border-radius: 18.5px;
  border: 1px solid $primary;
  font-size: 20px;
  color: $primary;
  text-align: center;
  padding: 6px 20px;
}
