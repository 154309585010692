.home-root {
  min-height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0px 32px 0px;
  flex-direction: column;
}
.home-root .category-section {
  width: 100%;
  max-width: 1636px;
  margin-bottom: 12px;
}
.home-root .category-section .category-container {
  width: 256px;
  height: 48px;
}
.home-root .home-layout {
  width: 100%;
  max-width: 1636px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 2fr;
  -moz-column-gap: 24px;
       column-gap: 24px;
  row-gap: 32px;
}
.home-root .home-layout section {
  width: 100%;
  max-width: 806px;
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.home-root .home-layout section .header {
  width: 100%;
  height: 56px;
  border-bottom: 1px solid #eaeaea;
  padding: 18px 20px;
  font-size: 18px;
  font-weight: 500;
  color: #202428;
}
.home-root .home-layout .picking-section {
  display: flex;
  align-items: center;
}
.home-root .home-layout .picking-section .content {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.home-root .home-layout .picking-section .pp,
.home-root .home-layout .picking-section .pe {
  display: flex;
}
.home-root .home-layout .picking-section .pp .wrapper,
.home-root .home-layout .picking-section .pe .wrapper {
  width: 252px;
  display: flex;
  flex-direction: column;
}
.home-root .home-layout .picking-section .pp .counter,
.home-root .home-layout .picking-section .pe .counter {
  width: 100%;
  height: 149px;
  padding: 17px 59px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
}
.home-root .home-layout .picking-section .pp .counter .type,
.home-root .home-layout .picking-section .pe .counter .type {
  font-size: 20px;
  color: #73858f;
}
.home-root .home-layout .picking-section .pp .counter .count,
.home-root .home-layout .picking-section .pe .counter .count {
  font-size: 32px;
  font-weight: bold;
  color: #1d3740;
}
.home-root .home-layout .picking-section .pp .linechart-container,
.home-root .home-layout .picking-section .pe .linechart-container {
  width: 100%;
  height: 100px;
  padding: 13px 28px;
}
.home-root .home-layout .picking-section .pp .counter-by-time,
.home-root .home-layout .picking-section .pe .counter-by-time {
  display: flex;
  flex-direction: column;
}
.home-root .home-layout .picking-section .pp .counter-by-time .item,
.home-root .home-layout .picking-section .pe .counter-by-time .item {
  width: 150px;
  height: 124.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.home-root .home-layout .picking-section .pp .counter-by-time .item .type,
.home-root .home-layout .picking-section .pe .counter-by-time .item .type {
  font-size: 14px;
  color: #73858f;
}
.home-root .home-layout .picking-section .pp .counter-by-time .item .count,
.home-root .home-layout .picking-section .pe .counter-by-time .item .count {
  font-size: 20px;
  font-weight: bold;
  color: #1d3740;
}
.home-root .home-layout .picking-section .divider {
  height: 90%;
  min-width: 1px;
  background-color: #eaeaea;
  align-self: center;
}
.home-root .home-layout .profit-section {
  display: flex;
  align-items: center;
}
.home-root .home-layout .profit-section .content {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 376px 428px;
  justify-items: center;
  align-items: center;
}
.home-root .home-layout .profit-section .content .display {
  height: 149px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.home-root .home-layout .profit-section .content .display .result {
  font-size: 32px;
  font-weight: bold;
  color: #d32d41;
}
.home-root .home-layout .profit-section .content .chart-container {
  width: 376px;
  height: 100px;
  padding: 16px 32px;
}
.home-root .home-layout .profit-section .content .calculator {
  grid-column: 2/3;
  grid-row: 1/3;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 21px;
}
.home-root .home-layout .profit-section .content .calculator .item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-root .home-layout .profit-section .content .calculator .item .label {
  position: relative;
  font-size: 14px;
  color: #1d3740;
  width: 81px;
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: flex-start;
}
.home-root .home-layout .profit-section .content .calculator .item .label img {
  width: 18px;
  height: 18px;
  cursor: help;
}
.home-root .home-layout .profit-section .content .calculator .item .label img:hover + div {
  visibility: visible;
  opacity: 1;
}
.home-root .home-layout .profit-section .content .calculator .item .input-container,
.home-root .home-layout .profit-section .content .calculator .item .dropbox-container,
.home-root .home-layout .profit-section .content .calculator .item .calc-button {
  flex-grow: 1;
}
.home-root .home-layout .profit-section .content .calculator .item .calc-button {
  height: 40px;
  background-color: #5fa87c;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.home-root .home-layout .picking-section,
.home-root .home-layout .profit-section {
  min-height: 305px;
}
.home-root .home-layout .pp-section,
.home-root .home-layout .pe-section {
  min-height: 599px;
}
.home-root .home-layout .pp-section .content,
.home-root .home-layout .pe-section .content {
  padding: 20px 20px 20px 41px;
  flex-grow: 1;
  display: flex;
  gap: 32px;
  flex-direction: column;
}
.home-root .home-layout .pp-section .toggle-area,
.home-root .home-layout .pe-section .toggle-area {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-root .home-layout .pp-section .toggle-area .toggle-container:first-child,
.home-root .home-layout .pe-section .toggle-area .toggle-container:first-child {
  width: 176px;
  height: 48px;
}
.home-root .home-layout .pp-section .toggle-area .toggle-container:last-child,
.home-root .home-layout .pe-section .toggle-area .toggle-container:last-child {
  width: 116px;
  height: 48px;
}
.home-root .home-layout .pp-section .chart-container,
.home-root .home-layout .pe-section .chart-container {
  flex-grow: 1;
}

@media (max-width: 1919px) {
  .home-root .category-section {
    max-width: 804px;
  }
  .home-root .home-layout {
    grid-template-columns: 1fr;
    grid-template-rows: none;
    -moz-column-gap: 24px;
         column-gap: 24px;
    row-gap: 32px;
    justify-items: center;
  }
}
@media (max-width: 1200px) {
  .home-root .home-layout .picking-section .content {
    flex-direction: column;
  }
  .home-root .home-layout .picking-section .pp,
  .home-root .home-layout .picking-section .pe {
    flex-direction: column;
    align-items: center;
  }
  .home-root .home-layout .picking-section .pp .counter-by-time,
  .home-root .home-layout .picking-section .pe .counter-by-time {
    flex-direction: row;
  }
  .home-root .home-layout .picking-section .divider {
    height: 1px;
    min-width: none;
    width: 100%;
  }
  .home-root .home-layout .profit-section .content {
    grid-template-columns: auto;
    justify-items: center;
    align-items: center;
  }
  .home-root .home-layout .profit-section .content .chart-container {
    grid-row: 2/3;
  }
  .home-root .home-layout .profit-section .content .calculator {
    grid-column: 1/2;
    grid-row: 3/4;
  }
}
@media (max-width: 600px) {
  .home-root .home-layout .pp-section .content,
  .home-root .home-layout .pe-section .content {
    max-width: calc(100vw - 40px);
  }
  .home-root .home-layout .pp-section .toggle-area,
  .home-root .home-layout .pe-section .toggle-area {
    justify-content: flex-start;
    gap: 15px;
  }
  .home-root .home-layout .pp-section .chart-container,
  .home-root .home-layout .pe-section .chart-container {
    overflow-x: scroll;
  }
}/*# sourceMappingURL=home.style.css.map */