@import '../../../../styles/colors';

.header-root {
  height: 80px;
  padding: 0px 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $primary-dark;

  .content-wrapper {
    position: relative;
    width: 100%;
    max-width: 1632px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .left {
    .toggle-container {
      width: 252px;
      height: 48px;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 500;
    color: $white;
  }

  .right {
    display: flex;
    align-items: center;
    gap: 12px;

    .profile-name {
      font-size: 15px;
      color: $white;
    }
    .default-img {
      width: 48px;
      height: 48px;
      background-image: image-set(
        url(/public/assets/icons/ic-user.png) 1x,
        url(/public/assets/icons/ic-user@2x.png) 2x,
        url(/public/assets/icons/ic-user@3x.png) 3x
      );
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
}

@media (max-width: 1200px) {
  .header-root {
    height: 160px;
    justify-content: space-between;

    .content-wrapper {
      height: 80px;
    }
    .left {
      width: 100%;
      position: absolute;
      bottom: -64px;
      display: flex;
      justify-content: center;
    }

    .title {
      flex-grow: 1;
      text-align: center;
    }

    .right {
      position: absolute;
      right: 0px;
      .profile-name {
        display: none;
      }
      .default-img {
      }
    }
  }
}

@media (max-width: 800px) {
  .header-root {
    padding: 0px 20px;

    .content-wrapper {
    }
    .left {
    }

    .title {
    }

    .right {
      .profile-name {
      }
      .default-img {
      }
    }
  }
}
