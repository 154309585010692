@import '../../../../styles/colors';

.category-root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 11px 16px;
  border: 1px solid $gray-2;
  border-radius: 4px;

  background-color: $white;

  .category-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      font-size: 14px;
      color: $gray-4;
    }
    .arrow {
      transition: transform 0.1s ease-in-out;
      transform: rotate(0deg);
      background-image: image-set(
        url(/public/assets/icons/ic-down.png) 1x,
        url(/public/assets/icons/ic-down@2x.png) 2x,
        url(/public/assets/icons/ic-down@3x.png) 3x
      );
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      width: 20px;
      height: 20px;
    }
  }

  .options-wrapper {
    position: absolute;
    transition: height 0.1s ease-in-out, padding 0.1s ease-in-out;
    z-index: 3;
    width: 100%;
    height: 0px;
    top: 52px;
    left: 0px;
    background-color: $white;
    overflow-y: hidden;

    .option-list {
      height: 100%;
      border: 1px solid $gray-2;
      border-radius: 4px;

      &:not(:hover) {
        .selected {
          color: $gray-4;
          background-color: $primary-light;
        }
      }

      .option {
        padding: 11px 16px;
        font-size: 14px;
        color: $gray-3;

        &:hover,
        &:active {
          color: $gray-4;
          background-color: $primary-light;
        }
      }
    }
  }

  &.focused {
    .category-box {
      .text {
      }
      .arrow {
        transform: rotate(180deg);
      }
    }

    .options-wrapper {
      height: 80px;
      .option-list {
        .option {
        }
      }
    }
  }
}
