$primary: #5fa87c;
$primary-dark: #498360;
$white: #fff;
$gray-4: #1d3740;
$gray-1: #f1f2f0;
$gray-2: #eaeaea;
$primary-light: #dfeee5;
$gray-3: #73858f;
$black: #202428;
$red-light: #fbe9eb;
$red: #d32d41;
$blue-light: #e7ecf1;
$blue: #1a4575;
