@import '../../../styles/colors';

.bordered-tooltip-root {
  position: absolute;
  top: 100%;
  left: 60%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s linear;

  svg {
    position: absolute;
    z-index: 1;
  }

  .text {
    background-color: $white;
    position: absolute;
    top: 8px;
    left: -48px;
    border: 1px solid $gray-2;
    padding: 8px 12px;
    white-space: nowrap;
    border-radius: 16px/50%;
    font-size: 12px;
    color: #767676;
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.16);
  }
}
