.header-root {
  height: 80px;
  padding: 0px 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #498360;
}
.header-root .content-wrapper {
  position: relative;
  width: 100%;
  max-width: 1632px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-root .left .toggle-container {
  width: 252px;
  height: 48px;
}
.header-root .title {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}
.header-root .right {
  display: flex;
  align-items: center;
  gap: 12px;
}
.header-root .right .profile-name {
  font-size: 15px;
  color: #fff;
}
.header-root .right .default-img {
  width: 48px;
  height: 48px;
  background-image: -webkit-image-set(url(/public/assets/icons/ic-user.png) 1x, url(/public/assets/icons/ic-user@2x.png) 2x, url(/public/assets/icons/ic-user@3x.png) 3x);
  background-image: image-set(url(/public/assets/icons/ic-user.png) 1x, url(/public/assets/icons/ic-user@2x.png) 2x, url(/public/assets/icons/ic-user@3x.png) 3x);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media (max-width: 1200px) {
  .header-root {
    height: 160px;
    justify-content: space-between;
  }
  .header-root .content-wrapper {
    height: 80px;
  }
  .header-root .left {
    width: 100%;
    position: absolute;
    bottom: -64px;
    display: flex;
    justify-content: center;
  }
  .header-root .title {
    flex-grow: 1;
    text-align: center;
  }
  .header-root .right {
    position: absolute;
    right: 0px;
  }
  .header-root .right .profile-name {
    display: none;
  }
}
@media (max-width: 800px) {
  .header-root {
    padding: 0px 20px;
  }
}/*# sourceMappingURL=header.style.css.map */