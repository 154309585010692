.login-root {
  width: 100%;
  height: 100vh;
  background-color: #f1f2f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-root .title-container .title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #202428;
}
.login-root .title-container .subtitle {
  font-size: 16px;
  text-align: center;
  color: #73858f;
  margin-top: 12px;
}
.login-root .login-form {
  margin-top: 40px;
  width: 444px;
  border: 1px solid #eaeaea;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
}
.login-root .login-form .header {
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  padding: 18px 32px 16px 32px;
  margin-bottom: 24x;
}
.login-root .login-form .item {
  width: 100%;
  padding: 0px 32px;
  margin-top: 24px;
}
.login-root .login-form .item .label {
  font-size: 14px;
  font-weight: 500;
  color: #1d3740;
}
.login-root .login-form .item .input-container {
  margin-top: 8px;
}
.login-root .login-form .login-button {
  width: calc(100% - 64px);
  margin-top: 40px;
  height: 40px;
  background-color: #5fa87c;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}/*# sourceMappingURL=login.style.css.map */