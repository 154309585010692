.expand-component {
  cursor: pointer;
  width: 28px;
  height: 28px;
  padding: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 4px;
  -moz-column-gap: 4px;
       column-gap: 4px;
  background-color: rgba(0, 0, 0, 0.4);
}
.expand-component div {
  width: 8px;
  height: 8px;
  transition: transform 0.3s;
}
.expand-component .piece-1 {
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
}
.expand-component .piece-2 {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
.expand-component .piece-3 {
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
}
.expand-component .piece-4 {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}
.expand-component:hover .piece-1 {
  transform: translate(-1px, -1px);
}
.expand-component:hover .piece-2 {
  transform: translate(1px, -1px);
}
.expand-component:hover .piece-3 {
  transform: translate(-1px, 1px);
}
.expand-component:hover .piece-4 {
  transform: translate(1px, 1px);
}/*# sourceMappingURL=video.expand.css.map */