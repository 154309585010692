.bordered-tooltip-root {
  position: absolute;
  top: 100%;
  left: 60%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s linear;
}
.bordered-tooltip-root svg {
  position: absolute;
  z-index: 1;
}
.bordered-tooltip-root .text {
  background-color: #fff;
  position: absolute;
  top: 8px;
  left: -48px;
  border: 1px solid #eaeaea;
  padding: 8px 12px;
  white-space: nowrap;
  border-radius: 16px/50%;
  font-size: 12px;
  color: #767676;
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.16);
}/*# sourceMappingURL=border.tooltip.css.map */