.dropdown-root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 11px 16px;
  border: 1px solid #eaeaea;
}
.dropdown-root .dropdown-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown-root .dropdown-box .text {
  font-size: 14px;
  color: #1d3740;
}
.dropdown-root .dropdown-box .arrow {
  transition: transform 0.1s ease-in-out;
  transform: rotate(0deg);
  background-image: -webkit-image-set(url(/public/assets/icons/ic-down.png) 1x, url(/public/assets/icons/ic-down@2x.png) 2x, url(/public/assets/icons/ic-down@3x.png) 3x);
  background-image: image-set(url(/public/assets/icons/ic-down.png) 1x, url(/public/assets/icons/ic-down@2x.png) 2x, url(/public/assets/icons/ic-down@3x.png) 3x);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 20px;
  height: 20px;
}
.dropdown-root .options-wrapper {
  position: absolute;
  transition: height 0.1s ease-in-out, padding 0.1s ease-in-out;
  z-index: 3;
  width: 100%;
  height: 0px;
  top: 44px;
  left: 0px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  overflow-y: hidden;
}
.dropdown-root .options-wrapper .option-list {
  height: 100%;
  border: 1px solid #eaeaea;
}
.dropdown-root .options-wrapper .option-list:not(:hover) .selected {
  background-color: #dfeee5;
}
.dropdown-root .options-wrapper .option-list .option {
  padding: 11px 16px;
  font-size: 14px;
  color: #1d3740;
}
.dropdown-root .options-wrapper .option-list .option:hover, .dropdown-root .options-wrapper .option-list .option:active {
  background-color: #dfeee5;
}
.dropdown-root.focused .dropdown-box .arrow {
  transform: rotate(180deg);
}
.dropdown-root.focused .options-wrapper {
  height: 80px;
}/*# sourceMappingURL=dropdown.style.css.map */