.colorchip-root {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: space-between;
  padding: 5px 8px;
  border-radius: 25%/50%;
}
.colorchip-root .amout {
  font-size: 14px;
}

.plus {
  background-color: #fbe9eb;
}
.plus .amount {
  color: #d32d41;
}

.minus {
  background-color: #e7ecf1;
}
.minus .amount {
  color: #1a4575;
}/*# sourceMappingURL=color.chip.style.css.map */