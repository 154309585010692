@import '../../../styles/colors';

.video-layout {
  width: 100%;
  min-height: calc(100vh - 80px);
  padding: 24px 144px 32px 144px;
  display: flex;
  flex-direction: column;
  align-items: center;

  section {
    max-width: 1636px;
  }

  .title-section {
    width: 100%;
    font-size: 20px;
    color: #111111;
    margin-bottom: 24px;
  }
  .video-section {
    position: relative;
    width: 100%;
    transition: all 0.2s;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));

    row-gap: 24px;
    column-gap: 24px;
  }
}

@media (max-width: 800px) {
  .video-layout {
    padding: 24px 60px 32px 60px;

    .title-section {
    }
    .video-section {
    }
  }
}

@media (max-width: 600px) {
  .video-layout {
    padding: 24px 0px 32px 0px;

    .title-section {
    }
    .video-section {
    }
  }
}
