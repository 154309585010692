@import '../../../../styles/colors';

.barchart-root {
  width: 100%;
  min-width: 520px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .upper-section {
    flex: 1;
    display: flex;

    .yaxes {
      width: 100px;
      height: 100%;
      display: flex;

      .label {
        display: flex;
        font-size: 12px;
        color: $gray-4;
        align-items: center;
      }

      .ticks {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        padding-right: 20px;

        .tick {
          text-align: right;
          font-size: 16px;
          color: $gray-4;
        }
      }
    }
    .graph {
      position: relative;
      flex: 1;

      .grid-y-layer {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .line-container {
          height: 21px;
          display: flex;
          align-items: center;
          &:last-child {
            align-items: flex-end;
          }
        }
        .line {
          height: 1px;
          width: 100%;
          background-color: $gray-2;
        }
      }

      .bar-layer {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;

        &:not(:hover) {
          .bar-container {
            &:last-child {
              .bar {
                background-color: $primary;
                scale: 1.05;

                .bar-label {
                  font-weight: bold;
                  font-size: 20px;
                  color: $gray-4;
                }
              }
            }
          }
        }

        .bar-container {
          flex: 1;
          height: calc(100% - 10px);
          display: flex;
          justify-content: center;
          align-items: flex-end;

          &:hover {
            .bar {
              background-color: $primary;
              scale: 1.05;

              .bar-label {
                font-weight: bold;
                font-size: 20px;
                color: $gray-4;
                z-index: 31;
              }
            }
          }
        }

        .bar {
          position: relative;
          transition: all 0.3s ease-in-out;
          width: 32px;
          background-color: $primary-light;
          display: flex;
          justify-content: center;

          .bar-label {
            position: absolute;
            transition: all 0.3s ease-in-out;
            top: -28px;
            font-size: 14px;
            color: $gray-3;
            z-index: 31;
          }
        }
      }
    }
  }

  .xaxes {
    height: 40px;
    padding-left: 100px;
    display: flex;

    .xaxis {
      flex: 1;
      display: flex;
      justify-content: center;
      margin-top: 16px;
      color: $gray-4;
    }
  }
}

@media (max-width: 640px) {
  .barchart-root {
    width: 100%;
    height: 100%;

    .upper-section {
      .yaxes {
      }
      .graph {
      }
    }

    .xaxes {
    }
  }
}
