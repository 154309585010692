@import '../../../../styles/colors';

.colorchip-root {
  width: fit-content;
  height: fit-content;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: space-between;
  padding: 5px 8px;
  border-radius: 25%/50%;

  .amout {
    font-size: 14px;
  }
}

.plus {
  background-color: $red-light;
  .amount {
    color: $red;
  }
}

.minus {
  background-color: $blue-light;
  .amount {
    color: $blue;
  }
}
