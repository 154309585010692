.barchart-root {
  width: 100%;
  min-width: 520px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.barchart-root .upper-section {
  flex: 1;
  display: flex;
}
.barchart-root .upper-section .yaxes {
  width: 100px;
  height: 100%;
  display: flex;
}
.barchart-root .upper-section .yaxes .label {
  display: flex;
  font-size: 12px;
  color: #1d3740;
  align-items: center;
}
.barchart-root .upper-section .yaxes .ticks {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding-right: 20px;
}
.barchart-root .upper-section .yaxes .ticks .tick {
  text-align: right;
  font-size: 16px;
  color: #1d3740;
}
.barchart-root .upper-section .graph {
  position: relative;
  flex: 1;
}
.barchart-root .upper-section .graph .grid-y-layer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.barchart-root .upper-section .graph .grid-y-layer .line-container {
  height: 21px;
  display: flex;
  align-items: center;
}
.barchart-root .upper-section .graph .grid-y-layer .line-container:last-child {
  align-items: flex-end;
}
.barchart-root .upper-section .graph .grid-y-layer .line {
  height: 1px;
  width: 100%;
  background-color: #eaeaea;
}
.barchart-root .upper-section .graph .bar-layer {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
.barchart-root .upper-section .graph .bar-layer:not(:hover) .bar-container:last-child .bar {
  background-color: #5fa87c;
  scale: 1.05;
}
.barchart-root .upper-section .graph .bar-layer:not(:hover) .bar-container:last-child .bar .bar-label {
  font-weight: bold;
  font-size: 20px;
  color: #1d3740;
}
.barchart-root .upper-section .graph .bar-layer .bar-container {
  flex: 1;
  height: calc(100% - 10px);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.barchart-root .upper-section .graph .bar-layer .bar-container:hover .bar {
  background-color: #5fa87c;
  scale: 1.05;
}
.barchart-root .upper-section .graph .bar-layer .bar-container:hover .bar .bar-label {
  font-weight: bold;
  font-size: 20px;
  color: #1d3740;
  z-index: 31;
}
.barchart-root .upper-section .graph .bar-layer .bar {
  position: relative;
  transition: all 0.3s ease-in-out;
  width: 32px;
  background-color: #dfeee5;
  display: flex;
  justify-content: center;
}
.barchart-root .upper-section .graph .bar-layer .bar .bar-label {
  position: absolute;
  transition: all 0.3s ease-in-out;
  top: -28px;
  font-size: 14px;
  color: #73858f;
  z-index: 31;
}
.barchart-root .xaxes {
  height: 40px;
  padding-left: 100px;
  display: flex;
}
.barchart-root .xaxes .xaxis {
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  color: #1d3740;
}

@media (max-width: 640px) {
  .barchart-root {
    width: 100%;
    height: 100%;
  }
}/*# sourceMappingURL=bar.chart.style.css.map */