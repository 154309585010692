@import '../../../../styles/colors';

.input-root {
  width: 100%;
  height: 100%;
  padding: 11px 16px;
  border: 1px solid $gray-2;
  color: $gray-4;

  &::placeholder {
    color: $gray-3;
  }

  &.filled {
    border-color: $primary;
  }
}
