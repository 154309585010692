@import '../../../styles/colors';

.home-root {
  min-height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0px 32px 0px;
  flex-direction: column;

  .category-section {
    width: 100%;
    max-width: 1636px;
    margin-bottom: 12px;
    .category-container {
      width: 256px;
      height: 48px;
    }
  }

  .home-layout {
    width: 100%;
    max-width: 1636px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 2fr;
    column-gap: 24px;
    row-gap: 32px;

    section {
      width: 100%;
      max-width: 806px;
      background-color: $white;
      border: 1px solid $gray-2;
      border-radius: 4px;
      display: flex;
      flex-direction: column;

      .header {
        width: 100%;
        height: 56px;
        border-bottom: 1px solid $gray-2;
        padding: 18px 20px;
        font-size: 18px;
        font-weight: 500;
        color: $black;
      }
    }

    .picking-section {
      display: flex;
      align-items: center;
      .content {
        display: flex;
        flex-grow: 1;
        align-items: center;
      }
      .pp,
      .pe {
        display: flex;

        .wrapper {
          width: 252px;
          display: flex;
          flex-direction: column;
        }
        .counter {
          width: 100%;
          height: 149px;
          padding: 17px 59px;
          display: flex;
          gap: 10px;
          flex-direction: column;
          align-items: center;
          .type {
            font-size: 20px;
            color: $gray-3;
          }
          .count {
            font-size: 32px;
            font-weight: bold;
            color: $gray-4;
          }
        }

        .linechart-container {
          width: 100%;
          height: 100px;
          padding: 13px 28px;
        }
        .counter-by-time {
          display: flex;
          flex-direction: column;

          .item {
            width: 150px;
            height: 124.5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;

            .type {
              font-size: 14px;
              color: $gray-3;
            }
            .count {
              font-size: 20px;
              font-weight: bold;
              color: $gray-4;
            }
          }
        }
      }
      .divider {
        height: 90%;
        min-width: 1px;
        background-color: $gray-2;
        align-self: center;
      }
    }

    .profit-section {
      display: flex;
      align-items: center;
      .content {
        flex-grow: 1;
        display: grid;
        grid-template-columns: 376px 428px;
        justify-items: center;
        align-items: center;

        .display {
          height: 149px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: center;
          justify-content: center;

          .result {
            font-size: 32px;
            font-weight: bold;
            color: $red;
          }
        }
        .chart-container {
          width: 376px;
          height: 100px;
          padding: 16px 32px;
        }
        .calculator {
          grid-column: 2/3;
          grid-row: 1/3;
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;
          padding: 21px;

          .item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .label {
              position: relative;
              font-size: 14px;
              color: $gray-4;
              width: 81px;
              display: flex;
              align-items: center;
              gap: 2px;
              justify-content: flex-start;

              img {
                width: 18px;
                height: 18px;
                cursor: help;

                &:hover + div {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }

            .input-container,
            .dropbox-container,
            .calc-button {
              flex-grow: 1;
            }

            .calc-button {
              height: 40px;
              background-color: $primary;
              color: $white;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }

    .picking-section,
    .profit-section {
      min-height: 305px;
    }
    .pp-section,
    .pe-section {
      min-height: 599px;

      .content {
        padding: 20px 20px 20px 41px;
        flex-grow: 1;
        display: flex;
        gap: 32px;
        flex-direction: column;
      }
      .toggle-area {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .toggle-container:first-child {
          width: 176px;
          height: 48px;
        }
        .toggle-container:last-child {
          width: 116px;
          height: 48px;
        }
      }

      .chart-container {
        flex-grow: 1;
      }
    }
  }
}

@media (max-width: 1919px) {
  .home-root {
    .category-section {
      max-width: 804px;

      .category-container {
      }
    }

    .home-layout {
      grid-template-columns: 1fr;
      grid-template-rows: none;
      column-gap: 24px;
      row-gap: 32px;
      justify-items: center;

      section {
        .header {
        }
      }

      .picking-section {
      }

      .profit-section {
      }

      .pp-section {
      }

      .pe-section {
      }

      .pp-section,
      .pe-section {
        .content {
        }
        .toggle-area {
          .toggle-container:first-child {
          }
          .toggle-container:last-child {
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .home-root {
    .home-layout {
      section {
        .header {
        }
      }

      .picking-section {
        .content {
          flex-direction: column;
        }
        .pp,
        .pe {
          flex-direction: column;
          align-items: center;
          .wrapper {
          }

          .linechart-container {
          }
          .counter-by-time {
            flex-direction: row;

            .item {
              .type {
              }
              .count {
              }
            }
          }
        }
        .divider {
          height: 1px;
          min-width: none;
          width: 100%;
        }
      }

      .profit-section {
        .content {
          grid-template-columns: auto;
          justify-items: center;
          align-items: center;

          .chart-container {
            grid-row: 2/3;
          }
          .calculator {
            grid-column: 1/2;
            grid-row: 3/4;
          }
        }
      }

      .pp-section {
      }

      .pe-section {
      }

      .pp-section,
      .pe-section {
        .content {
        }
        .toggle-area {
          .toggle-container:first-child {
          }
          .toggle-container:last-child {
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .home-root {
    .home-layout {
      section {
        .header {
        }
      }

      .picking-section {
        .content {
        }
        .pp,
        .pe {
          .wrapper {
          }

          .linechart-container {
          }
          .counter-by-time {
            .item {
              .type {
              }
              .count {
              }
            }
          }
        }
        .divider {
        }
      }

      .profit-section {
      }

      .pp-section {
      }

      .pe-section {
      }

      .pp-section,
      .pe-section {
        .content {
          max-width: calc(100vw - 40px);
        }
        .toggle-area {
          justify-content: flex-start;
          gap: 15px;
          .toggle-container:first-child {
          }
          .toggle-container:last-child {
          }
        }
        .chart-container {
          overflow-x: scroll;
        }
      }
    }
  }
}
