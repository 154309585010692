.expand-component {
  cursor: pointer;
  width: 28px;
  height: 28px;
  padding: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 4px;
  column-gap: 4px;
  background-color: rgba(0, 0, 0, 0.4);

  div {
    width: 8px;
    height: 8px;
    transition: transform 0.3s;
  }

  .piece-1 {
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
  }
  .piece-2 {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
  }
  .piece-3 {
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
  }
  .piece-4 {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
  }

  &:hover {
    .piece-1 {
      transform: translate(-1px, -1px);
    }
    .piece-2 {
      transform: translate(1px, -1px);
    }
    .piece-3 {
      transform: translate(-1px, 1px);
    }
    .piece-4 {
      transform: translate(1px, 1px);
    }
  }
}
