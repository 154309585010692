@import '../../../../styles/colors';

.tooltip-root {
  padding: 15px 16px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $gray-2;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.16);

  .x-value {
    color: $gray-3;
    font-size: 12px;
    margin-bottom: 8px;
  }
  .y-value {
    color: $gray-4;
    font-size: 16px;
    font-weight: bold;
  }
}
