@import '../../../styles/colors';

.video-item {
  width: 100%;
  height: 436px;
  border: 1px solid $gray-2;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s;

  .header {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray-2;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    gap: 12px;
    background-color: $white;

    .index-box {
      width: 36px;
      height: 36px;
      background-color: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      font-size: 18px;
      font-weight: 500;
      border-radius: 2px;
    }
    .device-name {
      font-size: 18px;
      font-weight: 500;
      color: #111111;
    }
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
    background-color: rgb(187, 183, 183);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
  }

  .video-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  .control-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;

    .size-btn {
      position: absolute;
      z-index: 10;
      top: 20px;
      right: 20px;
    }
    .power-state {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 120px;
      height: 40px;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.4);
    }
    .state {
      width: 8px;
      height: 8px;
      border: 1px solid $white;
      border-radius: 4px;
      margin-right: 6px;
      background: radial-gradient(circle at 50% 50%, #ff8171, #ff2b2b 71%);
    }
    .text {
      color: $white;
      font-size: 18px;
    }

    .off {
      .state {
        background: radial-gradient(circle at 50% 50%, #ccdde6, $gray-3 71%);
      }
      .text {
        color: $gray-3;
      }
    }
  }
}

.expanded {
  height: auto;
  min-height: 800px;
  grid-column: span 2;
  grid-row: span 2;
}
