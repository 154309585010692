@import '../../../../styles/colors';

.toggle-root {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $gray-1;
  border-radius: 24px;
  padding: 3px;

  .ball-layer {
    position: absolute;
    z-index: 1;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    display: flex;
    gap: 10px;

    div {
      flex: 1;
    }
    .ball {
      transition: transform 0.2s cubic-bezier(0.33, -0.44, 0.67, 1.36);
      transform: translateX();
      background-color: $white;
      border-radius: 21px;
    }
  }
  .text-layer {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    position: absolute;
    z-index: 2;
    display: flex;
    gap: 10px;

    .text-container {
      transition: color 0.2s linear;
      flex: 1;
      font-size: 15px;
      color: $gray-3;
    }
    .active {
      color: $gray-4;
    }
  }
}
